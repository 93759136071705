import React from 'react';
import Hero from './Hero'
import SeccionComercio from './SeccionComercio'
import DistribuidorMasCercano from './DistribuidorMasCercano'
import FormVendeMolinos from './FormVendeMolinos'

const VendeMolinos = (props) => {
    return (
        <>
          <Hero imgBanner="bg-vende-molinos" titlefirst="distribuí la" titleSecond="mejor calidad"/>
          <SeccionComercio/>
          <DistribuidorMasCercano/>
          <FormVendeMolinos/>
        </>
        )
    }
export default VendeMolinos;