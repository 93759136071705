import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { hydrate, render } from "react-dom";

const Index = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(Index, rootElement);
} else {
  render(Index, rootElement);
}
serviceWorker.unregister();
