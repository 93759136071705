import React from 'react';
import '../scss/Info.scss';
import ItemInfo from './ItemInfo.js';

const Info2 = (props) => {
    return (
            <div className="InfoProducto2 py-5 p-md-3 px-3">
                <img className="borde" src="/assets/borde-dark.svg" alt="borde"/>
                <div className="d-flex flex-column h-100 justify-content-between">
                    <div>
                        <img className="miscelanea" src="/assets/wave2.svg" alt=""/>
                        <h4 className="TituloProducto my-3 mt-md-5">{props.tituloSeccion}</h4>
                        <p>{props.infoProducto1}</p>
                        <p>{props.infoProducto2}</p>
                        <p>{props.infoProducto3}</p>
                        <p className="infoDestacado mb-5"><b>{props.infoDestacado}</b></p>
                    </div>
                    <div>
                        <div className="icons container p-0">
                            <div className="col-md-10">
                                <div className="row align-items-start justify-content-center">
                                    <ItemInfo icono="/assets/sustentable2.svg" bajada="SUSTENTABLES"/>
                                    <ItemInfo icono="/assets/semillas2.svg" bajada="CONOCEMOS NUESTRAS SEMILLAS"/>
                                    <ItemInfo icono="/assets/cuidados2.svg" bajada="PRODUCTOS CUIDADOS"/>
                                    <ItemInfo icono="/assets/sintacc.svg" bajada=""/>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        )
}

export default Info2;
    