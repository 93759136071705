import React from 'react';
import Hero from './Hero'
import Supermercados from './Supermercados'
import MercadoLibre from './MercadoLibre'
import BuscadorSucursales from './BuscadorSucursales'
import Consultas from './Consultas'
import '../scss/DondeEncontrarnos.scss'

const DondeEncontrarnos = (props) => {
    return (
        <>
            <Hero imgBanner="bg-donde-encontrarnos" titlefirst="encontranos" titleSecond="estés donde estés"/>
            <Supermercados/>
            <MercadoLibre/>
            <section className="buscadorSucursales">
                <div className="container d-flex flex-column justify-content-center align-items-center">
                    <h4 className="titleSucursales mb-5">Seleccioná tu provincia y conocé las principales cadenas donde podés encontrar nuestros productos.</h4>
                    <BuscadorSucursales/>
                </div>
            </section>
            <Consultas/>
        </>
        )
    }
export default DondeEncontrarnos;