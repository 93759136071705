import React from 'react';
import '../scss/Faqs.scss';


const Faqs = (props) => {
    return (
        <div className="container faqs">
            <div className="row justify-content-center align-items-center">
                <div className="col-sm-8">
                    <div id="accordion">
                        <div className="card">
                            <div className="card-header" id="headingOne">
                                <h5 className="mb-0 pf">
                                    <button className="btn btn-link collapsed btn-pf" data-toggle="collapse" data-target={`#${props.dataTarget}`} aria-expanded="false" aria-controls="collapseOne">
                                        <div className="row justify-content-between">
                                            <div className="pregunta"><p>{props.pregunta}</p></div>
                                            <div className="arrow"><img src="/assets/arrow-bottom.svg" alt=""/></div>

                                        </div>
                                    </button>
                                </h5>
                            </div>
                            <div id={props.dataTarget} className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body">
                                    {props.respuesta}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        )
    }
    export default Faqs;