import React from 'react';
import '../scss/Flip.scss';

const flip = (props) => {
    return (
            <div className="flip py-4 py-md-5 ">
                <div className="row">
                    <div className="col-2">
                        <p className="tipoDeProducto">PRODUCTO / {props.tipoDeProducto}</p>
                    </div>
                    <div className="col-10 d-flex">
                        <div className="imagenProducto">
                            <img className="bgMarco" src={props.colorMarco} alt="marco"/>
                            <div className={props.className}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    // function Underline() {
    //   return (
    //     <div className="underlinetext">
    //     </div>
    //   );
    // }
    
    export default flip;
    