import React from 'react';
import '../scss/Footer.scss';

const Footer = () => {
  return (
    <footer className="d-flex align-items-center justify-content-center">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-12 w-100 d-md-flex justify-content-md-center">
            <p className="pb-2 text-md-center"> Copyright 2020 / Molinos Ala. Todos los derechos reservados.  
            {/* CUIT 30-50689859-1. */}
             Una marca de Adecoagro </p>
            <img src="/assets/logo-adecoagro.png" alt="Adecoagro" />
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer;
