import React from 'react';
import HeroSlider from './HeroSlider';
import Hero from './Hero';
import Flip from './Flip';
import Flip2 from './Flip2';
import Info from './Info';
import Info2 from './Info2';
import VerticalSlider from './VerticalSlider';
import VerticalSlider2 from './VerticalSlider2';
import Politicasdeinocuidad from './Politicasdeinocuidad'
import '../scss/Arroz.scss'
import {
    BrowserRouter as
    Link
  } from "react-router-dom";


const Productos = (props) => {

    const data = [{
        id: 0,
        className: 'parboil',
        colorMarco: '/assets/marco-amarillo.svg',
        tipoDeProducto: 'Arroz',
        tituloSeccion:'no se pasa ni se pega',
        infoProducto1:'Se logra este arroz mediante un proceso totalmente natural, sin el agregado de sustancias químicas. El grano entero se sumerge en un baño de agua caliente. ',
        infoProducto2:'Luego se somete a vapor a presión y se seca. De esta manera los minerales y vitaminas que se encuentran en la cáscara se fijan al grano y el almidón de las capas externas se gelatiniza.',
        infoDestacado:'El resultado es un arroz nutritivo que no se pasa ni se pega.'
    }, {
        id: 1,
        className: 'largoFino',
        colorMarco: '/assets/marco-verde.svg',
        tipoDeProducto: 'Arroz',
        tituloSeccion:'largo fino',
        infoProducto1:'Es la variedad con la mejor presentación al plato, para cocinar arroz de aspecto atractivo. Los granos llegan al plato separados y con textura firme.',
        infoProducto2:'',
        infoDestacado:'Es ideal para platos fríos como ensaladas o salpicones, o para acompañar carnes y verduras.'
    }, {
        id: 2,
        className: 'carolina',
        colorMarco: '/assets/marco-rojo.svg',
        tipoDeProducto: 'Arroz',
        tituloSeccion:'doble carolina',
        infoProducto1:'Esta variedad se produce principalmente en nuestro país.',
        infoProducto2:'Según la tradición, se asocia con comidas más elaboradas. Al tratarse de un grano más grande con mayor tiempo de cocción, toma más el sabor de sus platos.',
        infoDestacado:'Es ideal para risottos, guisos y arroz con leche.'
    }, {
        id: 3,
        className: 'integral',
        colorMarco: '/assets/marco-marron.svg',
        tipoDeProducto: 'Arroz',
        tituloSeccion:'integral',
        infoProducto1:'Esta variedad a diferencia del arroz blanco conserva la capa interna de la cáscara (salvado y el germen).',
        infoProducto2:'',
        infoDestacado:'Es ideal para una alimentación sana y nutritiva.'
    }];
    const data2 = [{
        id: 0,
        className: 'Carnaroli',
        colorMarco: '/assets/marco-azul.svg',
        tipoDeProducto: 'Arroz',
        tituloSeccion:'Selección grandes sabores del mundo. Carnaroli',
        infoProducto1:'Variedad de origen italiano de grano medio, redondo y blanquecino. Debido a su alto contenido de almidón absorbe muy bien los sabores y genera una textura cremosa perfecta para risotto.',
        infoProducto2:'',
        infoDestacado:'El secreto para tus platos especiales, es un arroz especial.'
    }, {
        id: 1,
        className: 'yamani',
        colorMarco: '/assets/marco-yamani.svg',
        tipoDeProducto: 'Arroz',
        tituloSeccion:'Selección grandes sabores del mundo. Yamaní',
        infoProducto1:'Variedad de origen japonés de grano integral tipo corto. Ofrece una textura delicada y un sabor suave; por eso resulta ideal para servir con verduras o en ensaladas.',
        infoProducto2:'',
        infoDestacado:'El secreto para tus platos especiales, es un arroz especial.'
    }, 
    ];
    return (
        <>
            <Hero imgBanner="firstSlideArroz" titlefirst="un tipo de arroz" titleSecond="para cada ocasión"/>
            {/* <HeroSlider primerSlide="firstSlideArroz" segundoSlide="secondSlideArroz" firstLine="un tipo de arroz" secondLine="para cada ocasión"/> */}
            <div className="d-none d-md-block">
         <VerticalSlider2 data={data2} />
        </div>
      <div className="container d-md-none">
            <div className="row">
                <div className="col-12">
                    <Flip2 className="Carnaroli" colorMarco="/assets/marco-azul.svg" tipoDeProducto="Arroz"/>
                </div>
                <div className="col-12 infobackground2">
                    <Info2 tituloSeccion="Selección grandes sabores del mundo. Carnaroli" infoProducto1="Variedad de origen italiano de grano medio, redondo y blanquecino. Debido a su alto contenido de almidón absorbe muy bien los sabores y genera una textura cremosa perfecta para risotto." infoProducto2="" infoDestacado="El secreto para tus platos especiales, es un arroz especial." />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Flip2 className="yamani" colorMarco="/assets/marco-rojo.svg" tipoDeProducto="Arroz"/>
                </div>
                <div className="col-12 infobackground2">
                    <Info2 tituloSeccion="Selección grandes sabores del mundo. Yamaní" infoProducto1="Variedad de origen japonés de grano integral tipo corto. Ofrece una textura delicada y un sabor suave; por eso resulta ideal para servir con verduras o en ensaladas." infoProducto2="" infoDestacado="El secreto para tus platos especiales, es un arroz especial." />
                </div>
            </div>
        </div> 
            <div className="d-none d-md-block">
                <VerticalSlider data={data} />
            </div>
            <div className="container d-md-none">
                <div className="row contenedorSello">
                    <div className="col-12 mb-5">
                        <Flip className="parboil" colorMarco="/assets/marco-amarillo.svg" tipoDeProducto="Arroz"/>
                    </div>
                    <div className="col-12 infobackground">
                        <img className="selloMobile" src="/assets/sello.svg" alt ="sello"/>
                        <Info tituloSeccion="" infoProducto1="Se logra este arroz mediante un proceso totalmente natural, sin el agregado de sustancias químicas. El grano entero se sumerge en un baño de agua caliente. "infoProducto2="Luego se somete a vapor a presión y se seca. De esta manera los minerales y vitaminas que se encuentran en la cáscara se fijan al grano y el almidón de las capas externas se gelatiniza." infoDestacado="El resultado es un arroz nutritivo que no se pasa ni se pega." />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Flip className="largoFino" colorMarco="/assets/marco-verde.svg" tipoDeProducto="Arroz"/>
                    </div>
                    <div className="col-12 infobackground">
                        <Info tituloSeccion="largo fino" infoProducto1="Es la variedad con la mejor presentación al plato, para cocinar arroz de aspecto atractivo. Los granos llegan al plato separados y con textura firme."infoProducto2="" infoDestacado="Es ideal para platos fríos como ensaladas o salpicones, o para acompañar carnes y verduras." />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Flip className="carolina" colorMarco="/assets/marco-rojo.svg" tipoDeProducto="Arroz"/>
                    </div>
                    <div className="col-12 infobackground">
                        <Info tituloSeccion="doble carolina" infoProducto1="Esta variedad se produce principalmente en nuestro país."infoProducto2="Según la tradición, se asocia con comidas más elaboradas. Al tratarse de un grano más grande con mayor tiempo de cocción, toma más el sabor de sus platos." infoDestacado="Es ideal para risottos, guisos y arroz con leche." />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Flip className="integral" colorMarco="/assets/marco-marron.svg" tipoDeProducto="Arroz"/>
                    </div>
                    <div className="col-12 infobackground">
                        <Info tituloSeccion="integral" infoProducto1="Esta variedad a diferencia del arroz blanco conserva la capa interna de la cáscara (salvado y el germen)."infoProducto2="" infoDestacado="Es ideal para una alimentación sana y nutritiva." />
                    </div>
                </div>
            </div>
            {/* <div className="container my-5 w-100">
                <div className="d-flex justify-content-center align-items-center w-100">
                    <a href="/assets/politica_de_calidad_4.jpg" target="_blank" className="button-politics">Política de calidad e inocuidad alimentaria</a>
                </div>
            </div> */}
        </>
    )
}
export default Productos;