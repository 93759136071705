import React from 'react';
import '../scss/MercadoLibre.scss'

const MercadoLibre = () => {
    return (
        <section className="mercadolibre py-5 py-md-4 mb-5">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <h4 className="px-3 py-3">llegamos a todo el país con</h4>
                <div className="px-3 py-3">
                    <img src="/assets/mercado-libre.png" alt="MercadoLibre"/>
                </div>
                <div className="px-3 py-3"> 
                    <a href="https://listado.mercadolibre.com.ar/molinos-ala" className="btn-verde">
                        Comprar Online
                    </a>
                </div>
                </div>
            </div>
        </section>
        )
    }
export default MercadoLibre;