import React from 'react';
import '../scss/Consultas.scss'

const Consultas = () => {
    return (
        <section className="consultas py-5">
            <div className="container d-flex flex-column justify-content-center">
                <p>Para consultas particulares escribinos a</p>
                <p className="destacado">atencionalconsumidor@adecoagro.com</p>
            </div>
        </section>
        )
    }
export default Consultas;