import React from 'react';
import Underline from './Underline';
import FaqsVende from './FaqsVende'
import '../scss/DistribuidorMasCercano.scss'

const DistribuidorMasCercano = () => {
    return (
        <section className="DistribuidorMasCercano py-5">
            <div className="container faqs">
                <div className="row justify-content-center align-items-center">
                    <div className="col-sm-12">
                        <div id="accordion">
                            <div className="card">
                                <div className="card-header" id="headingOne">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#dataTarget" aria-expanded="false" aria-controls="collapseOne">
                                            <div className="row justify-content-center">
                                            <h3 className="pb-3">encontrá el distribuidor<br/><Underline className="before80v" underlinedtext="más cercano"/> </h3>
                                                <div className="justify-content-center align-items-center d-flex pl-md-4"><img className="arrow-sustentabilidad" src="/assets/arrow-bottom.svg" alt="arrow"/></div>
                                            </div>
                                        </button>
                                    </h5>
                                </div>
                                <div id="dataTarget" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div className="card-body">
                                        <div className="container">
                                            <div className="row d-none d-md-flex">
                                                <div className="Distribuidora col-20">
                                                    <p><span className="bold">DISTRIBUIDOR: </span> Lento</p>
                                                    <p><span className="bold">ZONA: </span> Gba Norte</p>
                                                    <p><span className="bold">CONTACTO COMERCIAL: </span> Santiago</p>
                                                    <p><span className="bold">TELEFONO: </span> 11 6055 5520</p>
                                                    <p><span className="bold">DETALLE: </span>Belgrano, Devoto, Palermo, Recoleta, Nuñez, Colegiales, Belgrano</p>
                                                </div>

                                                <div className="Distribuidora col-20">
                                                    <p><span className="bold">DISTRIBUIDOR: </span> Boop Srl</p>
                                                    <p><span className="bold">ZONA: </span> Gba Norte</p>
                                                    <p><span className="bold">CONTACTO COMERCIAL: </span>Pedro</p>
                                                    <p><span className="bold">TELEFONO: </span>11 3687 5525</p>
                                                    <p><span className="bold">DETALLE: </span>Pilar y Jose C Paz</p>
                                                </div>

                                                <div className="Distribuidora col-20">
                                                    <p><span className="bold">DISTRIBUIDOR: </span>Patfood</p>
                                                    <p><span className="bold">ZONA: </span>Gba Oeste</p>
                                                    <p><span className="bold">CONTACTO COMERCIAL: </span> Alejandro</p>
                                                    <p><span className="bold">TELEFONO: </span>11 3866 5716</p>
                                                    <p><span className="bold">DETALLE: </span>Merlo, La Matanza, Gral Rodriguez, Moreno, Marcos Paz, Las Heras</p>
                                                </div>

                                                <div className="Distribuidora col-20">
                                                    <p><span className="bold">DISTRIBUIDOR: </span>Latte SRL</p>
                                                    <p><span className="bold">ZONA: </span>Gba Oeste</p>
                                                    <p><span className="bold">CONTACTO COMERCIAL: </span>Emilce</p>
                                                    <p><span className="bold">TELEFONO: </span>11 6899 1496</p>
                                                    <p><span className="bold">DETALLE: </span>Tres de Febrero, Moron, Ituzaingo, Hurlingham, San Martin</p>
                                                </div>

                                                <div className="Distribuidora col-20">
                                                    <p><span className="bold">DISTRIBUIDOR: </span>Ulax</p>
                                                    <p><span className="bold">ZONA: </span>Capital Federal</p>
                                                    <p><span className="bold">CONTACTO COMERCIAL: </span>Favio</p>
                                                    <p><span className="bold">TELEFONO: </span>11 3787 1555</p>
                                                    <p><span className="bold">DETALLE: </span>Mataderos, Liniers, Flores, Floresta, Almagro, Urquiza, Villa del Parque</p>
                                                </div>



                                                <div className="Distribuidora col-20">
                                                    <p><span className="bold">DISTRIBUIDOR: </span>Campo de Sabores</p>
                                                    <p><span className="bold">ZONA: </span>Capital Federal</p>
                                                    <p><span className="bold">CONTACTO COMERCIAL: </span>Martin</p>
                                                    <p><span className="bold">TELEFONO: </span>11 6484 3119</p>
                                                    <p><span className="bold">DETALLE: </span>Belgrano, Devoto, Palermo, Recoleta, Nuñez, Colegiales</p>
                                                </div>
                                                <div className="Distribuidora col-20">
                                                    <p><span className="bold">DISTRIBUIDOR: </span>Alejandro Negro</p>
                                                    <p><span className="bold">ZONA: </span>Capital Federal</p>
                                                    <p><span className="bold">CONTACTO COMERCIAL: </span>Gabriel</p>
                                                    <p><span className="bold">TELEFONO: </span>11 2644 6871</p>
                                                    <p><span className="bold">DETALLE: </span>Avellaneda, La Boca, Lanus, Parque Patricios, San Telmo, Villa Soldati</p>
                                                </div>
                                                <div className="Distribuidora col-20">
                                                    <p><span className="bold">DISTRIBUIDOR: </span>Gid Sa</p>
                                                    <p><span className="bold">ZONA: </span>Gba Sur</p>
                                                    <p><span className="bold">CONTACTO COMERCIAL: </span>Marcelo</p>
                                                    <p><span className="bold">TELEFONO: </span> 11 3244 5389</p>
                                                    <p><span className="bold">DETALLE: </span> Lomas de Zamora, Ezeisa, San Vicente, Cañuelas</p>
                                                </div>
                                                <div className="Distribuidora col-20">
                                                    <p><span className="bold">DISTRIBUIDOR: </span>Salvador Sgro</p>
                                                    <p><span className="bold">ZONA: </span>Gba Sur</p>
                                                    <p><span className="bold">CONTACTO COMERCIAL: </span>Jose</p>
                                                    <p><span className="bold">TELEFONO: </span>11 5756 2404</p>
                                                    <p><span className="bold">DETALLE: </span>La plata, Ensenada, Beriso</p>
                                                </div>
                                                


                                                <div className="Distribuidora col-20">
                                                    <p><span className="bold">DISTRIBUIDOR: </span> Ruelo Ayacucho</p>
                                                    <p><span className="bold">ZONA: </span> Gba</p>
                                                    <p><span className="bold">CONTACTO COMERCIAL: </span> Santiago</p>
                                                    <p><span className="bold">TELEFONO: </span> 11 6055 5520</p>
                                                    <p><span className="bold">DETALLE: </span>Belgrano, Devoto, Palermo, Recoleta, Nuñez, Colegiales, Belgrano</p>
                                                </div>
                                                <div className="Distribuidora col-20">
                                                    <p><span className="bold">DISTRIBUIDOR: </span>La Venadence</p>
                                                    <p><span className="bold">ZONA: </span>Sur de Santa Fe</p>
                                                    <p><span className="bold">CONTACTO COMERCIAL: </span>Martin</p>
                                                    <p><span className="bold">TELEFONO: </span>54 9 341 521-6233</p>
                                                    <p><span className="bold">DETALLE: </span>Rosario, General Lopez, San Lorenzo, Constitución, San Jeronimo, Caseros, Irondo, San Martin y Belgrano</p>
                                                </div>
                                                <div className="Distribuidora col-20">
                                                    <p><span className="bold">DISTRIBUIDOR: </span>Pietrofarma</p>
                                                    <p><span className="bold">ZONA: </span>Mendoza</p>
                                                    <p><span className="bold">CONTACTO COMERCIAL: </span>Jonathan</p>
                                                    <p><span className="bold">TELEFONO: </span>54 9 2617 10-1079</p>
                                                    <p><span className="bold">DETALLE: </span>Toda la Provincia de Mendoza</p>
                                                </div>
                                                <div className="Distribuidora col-20">
                                                    <p><span className="bold">DISTRIBUIDOR: </span>Punto de Venta</p>
                                                    <p><span className="bold">ZONA: </span>NOA</p>
                                                    <p><span className="bold">CONTACTO COMERCIAL: </span>Sergio</p>
                                                    <p><span className="bold">TELEFONO: </span>54 9 3814 16-8837</p>
                                                    <p><span className="bold">DETALLE: </span>Provincia Tucuman, Catamarca, La Rioja y Santiago del Estero</p>
                                                </div>
                                                <div className="Distribuidora col-20">
                                                    <p><span className="bold">DISTRIBUIDOR: </span>Jota Be</p>
                                                    <p><span className="bold">ZONA: </span>NEA</p>
                                                    <p><span className="bold">CONTACTO COMERCIAL: </span>Contacto Distribuidora</p>
                                                    <p><span className="bold">TELEFONO: </span>Misiones(0376) 4420990 / Corrientes (0379) 4-866182</p>
                                                    <p><span className="bold">DETALLE: </span>Provincia Misiones, Corrientes, Chaco y Formosa</p>
                                                </div>


                                                <div className="Distribuidora col-20">
                                                    <p><span className="bold">DISTRIBUIDOR: </span>Comercio Riggio</p>
                                                    <p><span className="bold">ZONA: </span>Entre Rios</p>
                                                    <p><span className="bold">CONTACTO COMERCIAL: </span>Ariel</p>
                                                    <p><span className="bold">TELEFONO: </span>54 9 3456 25-0398</p>
                                                    <p><span className="bold">DETALLE: </span>Provincia de Entre Rios</p>
                                                </div>
                                                <div className="Distribuidora col-20">
                                                    <p><span className="bold">DISTRIBUIDOR: </span>DASA</p>
                                                    <p><span className="bold">ZONA: </span>Tierra del Fuego</p>
                                                    <p><span className="bold">CONTACTO COMERCIAL: </span>Julio</p>
                                                    <p><span className="bold">TELEFONO: </span>54 9 2964 56-5300</p>
                                                    <p><span className="bold">DETALLE: </span>Provincia Tierra del Fuego</p>
                                                </div>
                                                <div className="Distribuidora col-20">
                                                    <p><span className="bold">DISTRIBUIDOR: </span>Betel</p>
                                                    <p><span className="bold">ZONA: </span>Jujuy</p>
                                                    <p><span className="bold">CONTACTO COMERCIAL: </span></p>
                                                    <p><span className="bold">TELEFONO: </span></p>
                                                    <p><span className="bold">DETALLE: </span></p>
                                                </div>
                                                <div className="Distribuidora col-20">
                                                    <p><span className="bold">DISTRIBUIDOR: </span>Don Tomas</p>
                                                    <p><span className="bold">ZONA: </span>Neuquen</p>
                                                    <p><span className="bold">CONTACTO COMERCIAL: </span>Gustávo</p>
                                                    <p><span className="bold">TELEFONO: </span>54 9 2984 30-5359</p>
                                                    <p><span className="bold">DETALLE: </span>Provincia de Neuquen</p>
                                                </div>

                                            </div>
                                        </div>
                                            <div className="d-md-none">
                                            <FaqsVende dataTarget="collapseOne" distribuidor="Lento" zona="Gba Norte" contacto="Santiago" telefono=" 11 6055 5520" detalle="Belgrano, Devoto, Palermo, Recoleta, Nuñez, Colegiales, Belgrano"/>
                                            <FaqsVende dataTarget="collapseTwo" distribuidor="Boop Srl" zona="Gba Norte" contacto="Pedro" telefono="11 3687 5525" detalle="Pilar y Jose C Paz"/>
                                            <FaqsVende dataTarget="collapseThree" distribuidor="Patfood" zona="Gba Oeste" contacto="Alejandro" telefono="11 3866 5716" detalle="Merlo, La Matanza, Gral Rodriguez, Moreno, Marcos Paz, Las Heras"/>
                                            <FaqsVende dataTarget="collapseFour" distribuidor="Latte SRL" zona="Gba Oeste" contacto="Emilce" telefono="11 6899 1496" detalle="Tres de Febrero, Moron, Ituzaingo, Hurlingham, San Martin"/>
                                            <FaqsVende dataTarget="collapseFive" distribuidor="Ulax" zona="Capital Federal" contacto="Favio" telefono="11 3787 1555" detalle="Mataderos, Liniers, Flores, Floresta, Almagro, Urquiza, Villa del Parque"/>

                                            <FaqsVende dataTarget="collapseSix" distribuidor="Campo de Sabores" zona="Capital Federal" contacto="Martin" telefono="11 6484 3119" detalle="Belgrano, Devoto, Palermo, Recoleta, Nuñez, Colegiales, Belgrano"/>
                                            <FaqsVende dataTarget="collapseSeven" distribuidor="Alejandro Negro" zona="Capital Federal" contacto="Gabriel" telefono="11 2644 6871" detalle="Avellaneda, La Boca, Lanus, Parque Patricios, San Telmo, Villa Soldati"/>
                                            <FaqsVende dataTarget="collapseEight" distribuidor="Gid Sa" zona="Gba Sur" contacto="Marcelo" telefono="11 3244 5389" detalle="Lomas de Zamora, Ezeisa, San Vicente, Cañuelas"/>
                                            <FaqsVende dataTarget="collapseNine" distribuidor="Salvador Sgro" zona="Gba Sur" contacto="Jose" telefono="11 5756 2404" detalle="La plata, Ensenada, Beriso"/>

                                            <FaqsVende dataTarget="collapseTen" distribuidor="Ruelo Ayacucho" zona="Gba Norte" contacto="Santiago" telefono=" 11 6055 5520" detalle="Belgrano, Devoto, Palermo, Recoleta, Nuñez, Colegiales, Belgrano"/>
                                            <FaqsVende dataTarget="collapseEleven" distribuidor="La Venadence" zona="Sur de Santa Fe" contacto="Martin" telefono="54 9 341 521-6233" detalle="Rosario, General Lopez, San Lorenzo, Constitución, San Jeronimo, Caseros, Irondo, San Martin y Belgrano"/>
                                            <FaqsVende dataTarget="collapseTwelve" distribuidor="Pietrofarma" zona="Mendoza" contacto="Jonathan" telefono="54 9 2617 10-1079" detalle="Toda la Provincia de Mendoza"/>
                                            <FaqsVende dataTarget="collapseThirteen" distribuidor="Punto de Venta" zona="NOA" contacto="Sergio" telefono="54 9 3814 16-8837" detalle="Provincia Tucuman, Catamarca, La Rioja y Santiago del Estero"/>
                                            <FaqsVende dataTarget="collapseForteen" distribuidor="Jota Be" zona="NEA" contacto="Contacto Distribuidora" telefono="Misiones(0376) 4420990 / Corrientes (0379) 4-8661820" detalle="Provincia Misiones, Corrientes, Chaco y Formosa"/>

                                            <FaqsVende dataTarget="collapseFifteen" distribuidor="Comercio Riggio" zona="Entre Rios" contacto="Ariel" telefono="54 9 3456 25-0398" detalle="Provincia de Entre Rios"/>
                                            <FaqsVende dataTarget="collapseSixteen" distribuidor="DASA" zona="Tierra del Fuego" contacto="Julio" telefono="54 9 2964 56-5300" detalle="Provincia Tierra del Fuego"/>
                                            <FaqsVende dataTarget="collapseEighteen" distribuidor="Betel" zona="Jujuy" contacto="" telefono="" detalle=""/>
                                            <FaqsVende dataTarget="collapseNineteen" distribuidor="Don Tomas" zona="Neuquen" contacto="Gustávo" telefono="54 9 2984 30-5359" detalle="Provincia de Neuquen"/>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img className="bordeDistribuidor" src="/assets/borde.svg" alt="borde"/>
            
        </section>
        )
    }
export default DistribuidorMasCercano;