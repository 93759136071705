import React from 'react';
import '../scss/SeccionComercio.scss'

const SeccionComercio = () => {
    return (
        <section className="comercio py-5">
            <div className="container d-flex flex-column align-items-center justify-content-center">
                <h3 className="py-4">¿tenés un comercio y querés<br/> vender nuestros productos?</h3>
                <div className="col-md-7">
                    <div className="row">
                        <div className="col-md-8">
                            <p>Te invitamos a conocer toda nuestra línea de productos.</p>
                        </div>
                        <div className="col-md-4 d-flex align-items-center">
                            <a href="/assets/catalogo.pdf" target="_blank"className="btn-verde">ver catálogo</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        )
    }
export default SeccionComercio;