import React from 'react';


const Politicasdeinocuidad = () =>{
  return(
    <>
    <img src="/assets/politica_de_calidad_4jpg"/>
    </>
  )
}
export default Politicasdeinocuidad