import React from 'react';
import '../App.scss';
import { hydrate, render } from "react-dom";
import HeroSlider from './HeroSlider';
import HeroHome from './HeroHome';
import Titulo from './Titulo';
import ProductoArroz from './ProductoArroz';
import ProductoSnack from './ProductoSnack';
import RecetasSeccion from './RecetasSeccion';
import Redes from './Redes';

function Home() {
  return (
    <div className="Home">
      {/* <HeroSlider primerSlide="firstSlideHome" segundoSlide="firstSlideHome"/> */}
      <HeroHome imgBanner="firstSlideHome"/>
      <div className="">
        <section className="py-5 bgmap">
          <div className="container">
            <Titulo sinEfecto="de la " underlined="semilla " highlited="a tu mesa"/>
            <div className="d-none d-md-block">
              <img src="/assets/graph-desktop.png" alt="De La Semilla a tu mesa"/>
            </div>
          </div>
          <div className="d-md-none">
            <img src="/assets/graph-mobile.png" alt="De La Semilla a tu mesa"/>
          </div>
        </section>
        <ProductoArroz/>
        <ProductoSnack/>
        <RecetasSeccion/>
        <Redes/>
      </div>
    </div>
  );
}

export default Home;
