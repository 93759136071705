import React from 'react';
import BuscadorRecetas from './BuscadorRecetas'
import Hero from './Hero'

const Recetas = (props) => {
    return (
        <>
            <Hero imgBanner="banner-rice" titlefirst="probá todas" titleSecond="nuestras recetas"/>
            <BuscadorRecetas/>
        </>
        )
    }
export default Recetas;