import React, {useState} from 'react'
// import ScrollAnimation from 'react-animate-on-scroll';
import Flip from './Flip';
import Info from './Info';
import '../scss/VerticalSlider.scss'
import { useCallback, useReducer } from 'react';

const App = (props) => {
    const [activeID, setActiveID] = useState(0)
    const [wrapperStyle, setWrapperStyle] = useState( {
        backgroundImage: `url('${props.data[0].img}')`
    })
    const [panelStyle, setPanelStyle] = useState({
        background: props.data[0].colour
    })
    const [buttonHover, setButtonHover] = useState(false)
    const [buttonStyle, setButtonStyle] =  useState({
        color: '#ffffff'
    })

	const _changeActive = (id) => {
		setActiveID(id)
        setWrapperStyle ({
            backgroundImage: `url('${props.data[id].img}')`
        })
        setPanelStyle({
            backgroundColor: props.data[id].colour
        })
    }
    
	const _buttonColour = () => {
		if(!buttonHover){
			setButtonHover(true)
            setButtonStyle({
                color: props.data[activeID].colour
            })
		} else {
			setButtonHover(false)
            setButtonStyle({
                color: '#ffffff'
            })
		}
    }

    // const disableScroll = () => { document.body.style.overflow = 'hidden' }
    // // const enableScroll = () => { document.body.style.overflow = 'scroll' }
    // const handleWheel = (e) => {
    //     if(props.data.length - 1 === activeID){
    //         // enableScroll()
    //     } else{
    //         disableScroll()
    //         _changeActive(activeID + 1)
    //     }
    // }

    return (
            <section className="wrapper sliderVertical" style={wrapperStyle}>
                {/* <section className="wrapper sliderVertical" style={wrapperStyle}  onWheel={handleWheel} onClick={enableScroll}> */}
                <Selectors 
                    data={props.data}
                    activeID={activeID}
                    _changeActive={_changeActive}
                />
                <Panel 
                    data={props.data[activeID]}
                    panelStyle={panelStyle}
                    buttonStyle={buttonStyle}
                    _buttonColour={_buttonColour}
                />
            </section>
    );
}

const Panel = (props) => {

    return (
        <aside className="panel" style={props.panelStyle}>
            <div className=" contenedorSello">
                <img className="sello" src="/assets/sello.svg" alt ="sello"/>
                <div className="col-12 col-sm-6">
                    <Flip className={props.data.className} colorMarco={props.data.colorMarco} tipoDeProducto={props.data.className}/>
                </div>
                <div className="col-12 col-sm-6 infobackground">
                    <Info tituloSeccion={props.data.tituloSeccion} infoProducto1={props.data.infoProducto1}infoProducto2={props.data.infoProducto2}infoProducto3={props.data.infoProducto3} infoDestacado={props.data.infoDestacado} />
                </div>
            </div>
            {/* <h2 className="panel-header">{props.data.header}</h2>
            <p className="panel-info">{props.data.body}</p>
            <button className="panel-button" 
                style={props.buttonStyle}
                onMouseEnter={props._buttonColour}
                onMouseLeave={props._buttonColour}>
                Read More
            </button> */}
        </aside>
    );
}

const Selectors = (props) => {
  
	const _handleClick = (id) => {
		if (id !== props.activeID) {
			props._changeActive(id);
		}
    }
    return (
        <div className="selectors" >
            {props.data.map((item) => 
                <Selector 
                    id={item.id}
                    _handleClick={_handleClick}
                    activeID={props.activeID}
                />
            )}
            <ArrowSelector 
                id={props.activeID}
                _handleClick={() => props._changeActive(props.data.length - 1 === props.activeID ? 0 : props.activeID + 1)}
                activeID={props.activeID}
            />
        </div>
    );
}

const Selector = ({id, activeID, _handleClick}) => <div className={`selector${activeID === id ? ' active' : ''}`} onClick={() => _handleClick(id)}></div>

const ArrowSelector = ({id, _handleClick}) => <img  src="/assets/arrow-right.svg" alt="Arrow" className='arrow arrow-selector' onClick={() => _handleClick(id)} />

export default App;