import React from 'react';
import Hero from './Hero';
import Flip from './Flip';
import Info from './Info';
import VerticalSlider from './VerticalSlider'
import '../scss/Arroz.scss'

const Snacks = (props) => {

    const data = [{
        id: 0,
        className: 'tostadasClasicas',
        colorMarco: '/assets/borde-verde-snacks.svg',
        tipoDeProducto: 'Arroz',
        tituloSeccion:'tostadas clásicas',
        infoProducto1:'Estas tostadas son ricas y sumamente crocantes. No contienen gluten, por lo tanto son aptas para celíacos.',
        infoProducto2:'',
        infoProducto3:'',
        infoDestacado:'Son ideales para untar. '
    }, {
        id: 1,
        className: 'tostadasIntegrales',
        colorMarco: '/assets/borde-amarillo-snacks.svg',
        tipoDeProducto: 'Arroz',
        tituloSeccion:'tostadas integrales',
        infoProducto1:'Estas tostadas integrales son ricas, crocantes y además contienen fibra. No contienen gluten, por lo tanto son aptas para celíacos.',
        infoProducto2:'',
        infoProducto3:'',
        infoDestacado:' Son ideales para untar, ya sea en desayunos y meriendas.'
    }, {
        id: 2,
        className: 'tostadasIntegralesSinSal',
        colorMarco: '/assets/borde-celeste-snacks.svg',
        tipoDeProducto: 'Arroz',
        tituloSeccion:'tostadas integrales sin sal',
        infoProducto1:'Esta variedad integral sin sal agregada contiene fibra y son ideales para acompañar desayunos y meriendas con bajo contenido en sodio.',
        infoProducto2:'',
        infoProducto3:'',
        infoDestacado:'No contienen gluten, por lo tanto también son aptas para celíacos.'
    }, {
        id: 3,
        className: 'tostaditasClasicas',
        colorMarco: '/assets/borde-verde-snacks.svg',
        tipoDeProducto: 'Arroz',
        tituloSeccion:'tostaditas clásicas',
        infoProducto1:'Estas tostaditas son ricas y sumamente crocantes. No contienen gluten, por lo tanto son aptas para celíacos.',
        infoProducto2:'',
        infoProducto3:'',
        infoDestacado:'Son ideales para untar. Son ideales para picotear en todo momento.'
    }, {
        id: 4,
        className: 'tostaditasFortificadas',
        colorMarco: '/assets/borde-verde-claro-snacks.svg',
        tipoDeProducto: 'Arroz',
        tituloSeccion:'tostaditas fortificadas',
        infoProducto1:'Esta variedad de tostaditas de arroz está fortificada con vitaminas B1, B2, B3, B6, D, ácido fólico, hierro y zinc, esencial para mantener una dieta sana y equilibrada. ',
        infoProducto2:'',
        infoProducto3:'',
        infoDestacado:'Son ricas, crocantes y no contienen gluten.'
    }, {
        id: 5,
        className: 'tostadasSinSal',
        colorMarco: '/assets/borde-celeste-snacks.svg',
        tipoDeProducto: 'Arroz',
        tituloSeccion:'tostaditas sin sal',
        infoProducto1:'Esta variedad sin sal agregada es rica, crocante y pensada para aquellas dietas con bajo contenido en sodio. No contienen gluten, por lo tanto son aptas para celíacos.',
        infoProducto2:'',
        infoProducto3:'',
        infoDestacado:' Son ideales para picotear en todo momento.'
    }, {
        id: 6,
        className: 'minisTostaditasClasicas',
        colorMarco: '/assets/borde-verde-snacks.svg',
        tipoDeProducto: 'Arroz',
        tituloSeccion:'minis tostaditas clásicas',
        infoProducto1:'Estas tostaditas son ricas y sumamente crocantes. No contienen gluten, por lo tanto son aptas para celíacos.',
        infoProducto2:'',
        infoProducto3:'',
        infoDestacado:'Son ideales para untar. Son ideales para picotear en todo momento.'
    }, {
        id: 7,
        className: 'dips',
        colorMarco: '/assets/borde-gris-snacks.svg',
        tipoDeProducto: 'Arroz',
        tituloSeccion:'dips',
        infoProducto1:'Los Dips son finitos, ricos y súper crocantes. No contienen gluten, por lo tanto son aptos para celíacos.         ',
        infoProducto2:'',
        infoProducto3:'',
        infoDestacado:'Son ideales para acompañar tus picadas.'
    }];

    return (
        <>
        <Hero imgBanner="banner-snacks" titlefirst="tu mejor opción " titleSecond="para snackear"/>
        <div className="d-none d-md-block">
            <VerticalSlider data={data} />
        </div>
        <div className="container d-md-none">
            <div className="row contenedorSello">   
                <div className="col-12 mb-5">
                    <Flip className="tostadasClasicas" colorMarco="/assets/borde-verde-snacks.svg" tipoDeProducto="Snack"/>
                </div>
                <div className="col-12 infobackground">
                    
                    <img className="selloMobile" src="/assets/sello.svg" alt ="sello"/>
                    <Info tituloSeccion="tostadas clásicas" infoProducto1='Estas tostadas son ricas y sumamente crocantes. No contienen gluten, por lo tanto son aptas para celíacos. ' infoProducto2="" infoProducto3="" infoDestacado="Son ideales para untar." />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Flip className="tostadasIntegrales" colorMarco="/assets/borde-amarillo-snacks.svg" tipoDeProducto="Arroz"/>
                </div>
                <div className="col-12 infobackground">
                    <Info tituloSeccion="tostadas integrales" infoProducto1='Estas tostadas integrales son ricas, crocantes y además contienen fibra. No contienen gluten, por lo tanto son aptas para celíacos.' infoProducto2="" infoProducto3="" infoDestacado="Son ideales para untar, ya sea en desayunos y meriendas."/>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Flip className="tostadasIntegralesSinSal" colorMarco="/assets/borde-celeste-snacks.svg" tipoDeProducto="Arroz"/>
                </div>
                <div className="col-12 infobackground">
                    <Info tituloSeccion="tostadas integrales sin sal" infoProducto1='Esta variedad integral sin sal agregada contiene fibra y son ideales para acompañar desayunos y meriendas con bajo contenido en sodio.' infoProducto2="" infoProducto3="" infoDestacado="No contienen gluten, por lo tanto también son aptas para celíacos."/>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Flip className="tostaditasClasicas" colorMarco="/assets/borde-verde-snacks.svg" tipoDeProducto="Arroz"/>
                </div>
                <div className="col-12 infobackground">
                    <Info tituloSeccion="tostaditas clásicas" infoProducto1='Estas tostaditas son ricas y sumamente crocantes. No contienen gluten, por lo tanto son aptas para celíacos.' infoProducto2="" infoProducto3="" infoDestacado="Son ideales para untar. Son ideales para picotear en todo momento."/>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Flip className="tostaditasFortificadas" colorMarco="/assets/borde-verde-claro-snacks.svg" tipoDeProducto="Arroz"/>
                </div>
                <div className="col-12 infobackground">
                    <Info tituloSeccion="tostaditas fortificadas" infoProducto1='Esta variedad de tostaditas de arroz está fortificada con vitaminas B1, B2, B3, B6, D, ácido fólico, hierro y zinc, esencial para mantener una dieta sana y equilibrada. ' infoProducto2="" infoProducto3="" infoDestacado="Son ricas, crocantes y no contienen gluten."/>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Flip className="tostadasSinSal" colorMarco="/assets/borde-celeste-snacks.svg" tipoDeProducto="Arroz"/>
                </div>
                <div className="col-12 infobackground">
                    <Info tituloSeccion="tostaditas sin sal" infoProducto1='Esta variedad sin sal agregada es rica, crocante y pensada para aquellas dietas con bajo contenido en sodio. No contienen gluten, por lo tanto son aptas para celíacos.' infoProducto2="" infoProducto3="" infoDestacado="Son ideales para picotear en todo momento."/>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Flip className="minisTostaditasClasicas" colorMarco="/assets/borde-verde-snacks.svg" tipoDeProducto="Arroz"/>
                </div>
                <div className="col-12 infobackground">
                    <Info tituloSeccion="minis tostaditas clásicas" infoProducto1='Estas tostaditas son ricas y sumamente crocantes. No contienen gluten, por lo tanto son aptas para celíacos.' infoProducto2="" infoProducto3="- " infoDestacado="Son ideales para untar. Son ideales para picotear en todo momento."/>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Flip className="dips" colorMarco="/assets/borde-gris-snacks.svg" tipoDeProducto="Arroz"/>
                </div>
                <div className="col-12 infobackground">
                    <Info tituloSeccion="dips" infoProducto1='Los Dips son finitos, ricos y súper crocantes. No contienen gluten, por lo tanto son aptos para celíacos.' infoProducto2="" infoProducto3="" infoDestacado="Son ideales para acompañar tus picadas."/>
                </div>
            </div>
        </div>
        </>
        )
    }
export default Snacks;