import React from 'react';
// import Titulo from './Titulo';
import Underline from './Underline';
import Highlighted from './Highlighted';
import recipes from './Recetas.json'
import { Link, NavLink } from 'react-router-dom';
import '../scss/RecetasSeccion.scss'

const RecetaSeccion = (props) => {
    // const [comidas, setComidas] = useState()
  
    // const handleChange = (event) => setComidas(event.target.value)
    // const filteredRecipes = comidas ? recipes.filter(recipe => recipe.comidas === comidas) : recipes

    return (
        <section className="seccionRecetas">
            <div className="container seccionRecetas py-5">
                <h1 className="tituloRecetas mb-5 mb-md-5">
                    <div className="row align-items-center">
                        <Underline className="color-green" underlinedtext="recetas" />
                        <div className="d-flex flex-column small pl-1">
                            <Highlighted className="smallest" highlightedText="super"/>
                            <Highlighted highlightedText="ricas"/>
                            <p className="mb-0">y faciles</p>
                        </div>
                    </div>
                    
                </h1>
                <div>
                <div id="carouselExampleControls" className="carousel slide d-md-none" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item asd active">
                            <img className="d-block sliderRecetas" src="/assets/turron-thumb.jpg" alt="First slide"/>
                            <a className="nombreReceta pl-2" href="#" >turron de avena<img src="/assets/arrow-01.svg" alt="Flecha"/></a>
                        </div>
                        <div className="carousel-item asd">
                            <img className="d-block sliderRecetas" src="/assets/mousse-thumb.jpg" alt="Second slide"/>
                            <a className="nombreReceta pl-2" href="#" >mousse de chocolate<img src="/assets/arrow-01.svg" alt="Flecha"/></a>
                        </div>
                        <div className="carousel-item asd">
                            <img className="d-block sliderRecetas" src="/assets/gremolata-thumb.jpg" alt="Third slide"/>
                            <a className="nombreReceta pl-2" href="#" >gremolata<img src="/assets/arrow-01.svg" alt="Flecha"/></a>
                        </div>
                        <div className="carousel-item asd">
                            <img className="d-block sliderRecetas" src="/assets/zapallitos-thumb.jpg" alt="Third slide"/>
                            <a className="nombreReceta pl-2" href="#" >zapallitos empanados<img src="/assets/arrow-01.svg" alt="Flecha"/></a>
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
                </div>
                
                <div className="d-md-none d-flex justify-content-center">
                    <Link to="/recetas">
                        <p className="btnVerde" href="#">Ver más Recetas</p>
                    </Link>
                </div>

                <div className="d-none d-md-block">
                    <div className="row">
                        <div className="col-8">
                            <div className="row flex-column">
                                <div className="col-8 p-0 ml-auto">
                                    <Link to="/recetas/5">
                                        <div className="p-0 ml-auto">
                                            <img className="recetaHome" src="/assets/turron.jpg" alt="Comida"/>
                                            <a className="nombreReceta" href="#">TURRÓN DE AVENA <img src="/assets/arrow-01.svg" alt="Flecha"/></a>
                                        </div>
                                    </Link>
                                </div>
                                <div className="row pr-3">
                                    <div className="col-6">
                                        <Link to="/recetas/15" className="col-6">
                                            <img className="recetaHome" src="/assets/zapallitos.jpg" alt="Comida"/>
                                            <a className="nombreReceta" href="#">Zapallitos Empanados <img src="/assets/arrow-01.svg" alt="Flecha"/></a>
                                        </Link>
                                    </div>
                                    <div className="col-6">
                                        <Link to="/recetas/16" className="col-6">
                                            <img className="recetaHome" src="/assets/gremolata.jpg" alt="Comida"/>
                                            <a className="nombreReceta" href="#">Gremolata <img src="/assets/arrow-01.svg" alt="Flecha"/></a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <Link to="/recetas/8">
                                <img className="recetaHome" src="/assets/mousse.jpg" alt="Comida"/>
                                <a className="nombreReceta" href="#">Mousse de chocolate<img src="/assets/arrow-01.svg" alt="Flecha"/></a>
                            </Link>
                            <div className="d-flex justify-content-start mt-4">
                            <Link to="/recetas">
                                <p className="btnVerde" href="#">Ver más Recetas</p>
                            </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        )
    }
export default RecetaSeccion;