import React from 'react';
import Hero from './Hero'
import SustentabilidadSections from './SustentabilidadSections'

const Sustentabilidad = () => {
    return (
        <>
            <Hero imgBanner="bg-sustentabilidad" titlefirst="" titleSecond="modelo sustentable"/>
            <SustentabilidadSections/>
            <section className="saberMas py-5 position-relative">
                <img className="barra-superior" src="/assets/borde-inferior-medio.svg"/>
                <div className="container d-flex flex-column justify-content-center align-items-center">
                    <h4 className="mb-3 mb-md-4">¿querés saber más sobre sustentabilidad?</h4>
                    <a href="https://sustainability.adecoagro.com/" className="btn-verde">conocer más</a>
                </div>
            </section>
        </>
        )
    }
export default Sustentabilidad;