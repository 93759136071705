import React from 'react';
import '../scss/Supermercados.scss'

const Supermercados = () => {
    return (
        <section className="supermercados my-5">
            <div className="container">
                <div className="d-flex justify-content-center">
                    <h4 className="col-md-4 d-flex justify-content-center">Encontranos en las cadenas de supermercados y mayoristas a nivel nacional.</h4>
                </div>
                <div className="row">
                    <div className="col-6 col-md-3"><img src="/assets/logo-coto.jpg" alt=""/></div>
                    <div className="col-6 col-md-3"><img src="/assets/logo-dia.jpg" alt=""/></div>
                    <div className="col-6 col-md-3"><img src="/assets/logo-la-anonima.jpg" alt=""/></div>
                    <div className="col-6 col-md-3"><img src="/assets/logo-walmart.jpg" alt=""/></div>
                    <div className="col-6 col-md-3"><img src="/assets/logo-carrefour.jpg" alt=""/></div>

                    <div className="col-6 col-md-3"><img src="/assets/logo-disco.jpg" alt=""/></div>
                    <div className="col-6 col-md-3"><img src="/assets/logo-jumbo.jpg" alt=""/></div>
                    <div className="col-6 col-md-3"><img src="/assets/logo-vea.jpg" alt=""/></div>
                    <div className="col-6 col-md-3"><img src="/assets/logo-vital.jpg" alt=""/></div>
                    <div className="col-6 col-md-3"><img src="/assets/logo-maxiconsumo.jpg" alt=""/></div>

                    <div className="col-6 col-md-3"><img src="/assets/logo-diarco.jpg" alt=""/></div>
                    <div className="col-6 col-md-3"><img src="/assets/logo-yaguar.jpg" alt=""/></div>
                    <div className="col-6 col-md-3"><img src="/assets/logo-nini.jpg" alt=""/></div>
                    <div className="col-6 col-md-3"><img src="/assets/logo-macro.jpg" alt=""/></div>
                </div>

            </div>
        </section>
        )
    }
export default Supermercados;