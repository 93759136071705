import React from 'react';
import Underline from './Underline'
import '../scss/Hero.scss'

const Hero = (props) => {
    return (
        <div className="hero">
            <div className="img-container">
                <div className={`resize_fit_center ${props.imgBanner}`}></div>
            </div>
            <h3 className="title d-md-none"> {props.titlefirst}<br/>
                <Underline className="before120" underlinedtext={props.titleSecond}/>
            </h3>
            <h3 className="titleDesktop d-none d-md-block">{props.titlefirst}<br/>
                <Underline className="before80" underlinedtext={props.titleSecond}/>
            </h3>
            <img className="bordeHero" src="/assets/borde.svg" alt="borde"/>
        </div>
        )
    }
export default Hero;