import React from 'react';
import { Link } from 'react-router-dom';
import Highlighted from './Highlighted';
import '../scss/ProductoSnacks.scss'

const ProductoSnacks = () => {
    return (
        <section className="productoSnacks py-1">
            <img className="bgRice" src="/assets/bg-rice-05.png" alt="arrow"/>
            <img className="rice09 d-none d-md-flex" src="/assets/bg-rice-07.png"/>
            <img className="rice10 d-none d-md-flex" src="/assets/bg-rice-07.png"/>
            <img className="photo006 d-none d-md-flex" src="/assets/bg-tostada-01.png"/>
            <img className="photo007 d-none d-md-flex" src="/assets/bg-tostada-02.png"/>
            <div className="container h-100">
                <div className="row h-100">
                    <div className="col-md-6 snackSeccion w-100 align-items-center">
                        <img className="ricephoto photo01" src="/assets/packs_tostadas.png" alt="arrow"/>
                        {/* <img className="snackPhoto photo001" src="/assets/tostadas-01.png" alt="arrow"/>
                        <img className="snackPhoto photo002" src="/assets/tostadas-02.png" alt="arrow"/>
                        <img className="snackPhoto photo003" src="/assets/tostadas-03.png" alt="arrow"/>
                        <img className="snackPhoto photo004" src="/assets/tostadas-04.png" alt="arrow"/>
                        <img className="snackPhoto photo005" src="/assets/tostadas-05.png" alt="arrow"/> */}
                    </div>
                    <div className="col-md-6 d-flex justify-content-center justify-content-md-start py-5 py-md-0">
                        <a href="/snacks" className="d-flex">
                            <div className="circle d-flex flex-column justify-content-center align-items-center">
                                <img className="icon" src="/assets/icon-snacks.svg" alt="snacks"/>
                                <p>snacks</p>
                                <Highlighted highlightedText="ver más"/>
                            </div> 
                        </a>
                    </div>
                </div>
            </div>
        </section>
        )
    }
export default ProductoSnacks;