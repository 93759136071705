import React from 'react';
import logo from '../logo.svg';
import Faqs from './Faqs';
import BuscadorRecetas from './BuscadorRecetas'
import ModuloReceta from './ModuloReceta'
const Productos = (props) => {
    return (
        <>
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
           
            </header>
           
            <div>
            <Faqs dataTarget="collapseOne" pregunta="Lorem ipsum dolor sit amet." respuesta="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod."/>
            <Faqs dataTarget="collapseTwo" pregunta="Lorem ipsum dolor sit amet." respuesta="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod."/>
            <Faqs dataTarget="collapseThree" pregunta="Lorem ipsum dolor sit amet." respuesta="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod."/>
            <Faqs dataTarget="collapseFour" pregunta="Lorem ipsum dolor sit amet." respuesta="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod."/>
            <Faqs dataTarget="collapseFive" pregunta="Lorem ipsum dolor sit amet." respuesta="Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod."/>
            </div>
            <div>
            <BuscadorRecetas/>
            </div>
            <div>
            <ModuloReceta/>
            </div>
            
      {/* <div className="row mt-4 mb-10">
        <div className="card">
          <div className="card-body">
            <h2>Lorem, ipsum dolor.</h2>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
          </div>
        </div>
        <div className="card">
          <div className="card-body bg-verde">
            <h2>Minima, cupiditate dolorum?</h2>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quibusdam, libero nisi? Eius sed, nihil corporis veritatis ex earum eveniet ipsum reprehenderit aliquam.</p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h2 className="text-verde">Officia, aut quae?</h2>
            <p>Lorem ipsum dolor sit amet.</p>
          </div>
        </div>
      </div> */}
        </>
        )
    }
export default Productos;
