import React from 'react';
import Underline from './Underline';
import Highlighted from './Highlighted';
import { Link, NavLink } from 'react-router-dom';
import '../scss/ProductoArroz.scss';

const ProductoArroz = () => {
    return (
        <section className="productoArroz pt-5">
            <img className="bgElement rice01" src="/assets/bg-rice-01.png"/>
            <img className="bgElement rice02" src="/assets/bg-rice-02.png"/>
            <img className="bgElement rice03" src="/assets/bg-rice-03.png"/>
            <img className="bgElement rice06 d-none d-md-flex" src="/assets/bg-rice-06.png"/>
            <img className="bgElement rice07 d-none d-md-flex" src="/assets/bg-rice-06.png"/>
            <img className="bgElement rice08 d-none d-md-flex" src="/assets/bg-rice-06.png"/>
            <div className="container">
                <h3 className="text-center mb-3"><Underline className="color-green" underlinedtext="productos"/></h3>
                <div className="row">
                    <div className="col-md-6 d-flex justify-content-center justify-content-md-end">
                    <Link to="/arroz">
                        <a href="/arroz" className="d-flex">
                            <div className="circle d-flex flex-column justify-content-center align-items-center">
                                <img className="icon" src="/assets/icon-rice.svg" alt="rice"/>
                                <p>arroz</p>
                                <Highlighted highlightedText="ver más"/>
                            </div>
                        </a>
                        </Link>
                    </div> 
                    <div className="col-md-6 riceSeccion">
                        <img className="ricephoto photo01" src="/assets/rice-01.png" alt="arrow"/>
                        <img className="ricephoto photo02" src="/assets/rice-02.png" alt="arrow"/>
                    </div>
                </div>
            </div>

        </section>
        )
    }
export default ProductoArroz;