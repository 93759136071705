import React from 'react';
import {BrowserRouter as Router, HashRouter, Switch,Route,Link} from "react-router-dom";
import '../scss/Faqs.scss';


const Faqs = (props) => {
    return (
        <div className="faqs">
            <div className="row justify-content-center align-items-center">
                <div className="col-12 p-0">
                    <div id="accordion">
                        <div className="card">
                            <div className="card-header-mobile" id="headingOne">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target={`#${props.dataTarget}`} aria-expanded="false" aria-controls="collapseOne">
                                        <div className="row justify-content-between w-100 m-auto">
                                            <div><p>{props.pregunta}</p></div>
                                            <div><img src="/assets/arrow-bottom.svg" alt=""/></div>

                                        </div>
                                    </button>
                                </h5>
                            </div>
                            <div id={props.dataTarget} className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body menumobile">
                                    <Link to={props.linkone} onClick={props.handleClick}>{props.nameOne}</Link>
                                </div>
                                <div className="card-body menumobile mobilebot">
                                    <Link to={props.linktwo} onClick={props.handleClick}>{props.nameTwo}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        )
    }
    export default Faqs;