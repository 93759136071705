import React from 'react';
import '../scss/ItemInfo.scss';

const ItemInfo = (props) => {
    return (
            <div className="col-3 ItemInfo">
                <img src={props.icono} alt="icono"/>
                <p>{props.bajada}</p>
            </div>
    )}
export default ItemInfo;            