import React from 'react';
import '../scss/Highlighted.scss';

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(<Home />, rootElement);
// } else {
//   render(<Home />, rootElement);
// }
const Highlighted = (props) => {
return (
        <span className="highlightedText">{props.highlightedText}</span>
    )
}

// function Underline() {
//   return (
//     <div className="underlinetext">
//     </div>
//   );
// }

export default Highlighted;
