import React, {useState} from 'react';
import {BrowserRouter as Router, HashRouter, Switch,Route,Link} from "react-router-dom";
import './scss/Header.scss';
import Home from './components/Home'
import Productos from './components/Productos'
import Recetas from './components/Recetas'
import LibreDeGluten from './components/LibreDeGluten'
import DondeEncontrarnos from './components/DondeEncontrarnos'
import Sustentabilidad from './components/Sustentabilidad'
import VendeMolinos from './components/VendeMolinos'
import Contacto from './components/Contacto'
import Footer from './components/Footer'
import Arroz from './components/Arroz'
import Snacks from './components/Snacks'
import PaginaReceta from './components/PaginaReceta'
import Dropdown from './components/Dropdown'
import Politicasdeinocuidad from './components/Politicasdeinocuidad'

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
const NoMatch = () => <div>404 Not Found</div>;



const ScrollToTop = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Routes = (props) => {
    const [menuOpen, setMenuOpen] = useState(false)

    const toggleMenu = () => setMenuOpen(!menuOpen)

    return (
        <Router>
            <ScrollToTop />
             <div className="header">
                <div className="container">
                    <div className="row justify-content-between align-items-center m-auto h-100">
                        <div className="col-8 col-lg-2 logo">
                            <Link to="/"><img className="logoMolinos" src="/assets/logo.svg" alt=""/></Link>
                        </div>
                        <div className="d-lg-none">
                            <div className={`menu-btn ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                                <div className="menu-btn__burger">
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-lg-flex col-md-10 navbar navbar-expand-lg">
                            <nav className="col-10 navbar-collapse">
                                <ul className="d-flex w-100 justify-content-between align-items-center navbar-nav">
                                    <li className="nav-item dropdown">
                                        <p className="nav-link dropdown-toggle mb-0"  href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" to="#">PRODUCTOS</p>
                                        <div className="dropdown-menu p-0" aria-labelledby="navbarDropdown">
                                            <ul className="collapseNav">
                                                <li>
                                                    <Link to="/arroz">Arroz</Link>
                                                </li>
                                                <li>
                                                    <Link to="/snacks">Snacks</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <Link to="/recetas">RECETAS</Link>
                                    <Link to="/libre-de-gluten">LIBRE DE GLUTEN</Link>
                                    <Link to="/donde-encontrarnos">ENCONTRANOS</Link>
                                    <Link to="/sustentabilidad">MODELO SUSTENTABLE</Link>
                                    <li className="nav-item dropdown">
                                        <p className="nav-link dropdown-toggle mb-0"  href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" to="#">VENDÉ MOLINOS ALA</p>
                                        <div className="dropdown-menu p-0" aria-labelledby="navbarDropdown">
                                            <ul className="collapseNav">
                                                <li>
                                                    <Link to="/vende-molinos-ala">DISTRIBUIDORES</Link>
                                                </li>
                                                <li>
                                                    <HashLink to="/vende-molinos-ala#formVendeMolinos" smooth>¿QUÉRES DISTRIBUIR?</HashLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                            <div className="col-2 d-none d-md-block redes-sm justify-content-between">
                                <div className="d-flex justify-content-around">
                                    <a className="facebook" href="https://es-la.facebook.com/MOLINOSALA/"  target="_blank"><img src="/assets/sm-face.svg" alt="Facebook"/></a>
                                    <a className="insta" href="https://www.instagram.com/molinosala/?hl=es-la"  target="_blank"><img src="/assets/sm-insta.svg" alt="Instagram"/></a>
                                    {/* <a className="tiktok" href="https://www.tiktok.com/@molinosala"  target="_blank"><img src="/assets/sm-tiktok.svg" alt="TikTok"/></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-lg-none">
                    <div className={`d-none ${menuOpen ? 'menuBurger' : ''}`}>
                        <div className="row">

                            <div className="col-12">
                                <ul className="d-flex flex-column px-3">
                                    <li className="pb-0">
                                        <Dropdown dataTarget="collapseOne" pregunta="PRODUCTOS" linkone='/arroz' nameOne="ARROZ" nameTwo="SNACKS" linktwo='/snacks' handleClick={toggleMenu}/>
                                    </li>
                                    <li><Link to="/recetas" onClick={toggleMenu}>RECETAS</Link></li>
                                    <li><Link to="/libre-de-gluten" onClick={toggleMenu}>LIBRE DE GLUTEN</Link></li>
                                    <li><Link to="/donde-encontrarnos" onClick={toggleMenu}>ENCONTRANOS</Link></li>
                                    <li><Link to="/sustentabilidad" onClick={toggleMenu}>MODELO SUSTENTABLE</Link></li>
                                    <li className="pb-0">
                                        <Dropdown dataTarget="collapseTwo" pregunta="VENDÉ MOLINOS ALA" linkone='/vende-molinos-ala' nameOne="DISTRIBUIDORES" nameTwo="¿QUÉRES DISTRIBUIR?" linktwo='/vende-molinos-ala#formVendeMolinos' handleClick={toggleMenu}/>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-6 redes-sm">
                                <div className="d-flex justify-content-around">
                                    <a className="facebook" href="https://es-la.facebook.com/MOLINOSALA/"><img src="/assets/sm-face.svg" alt="Facebook"/></a>
                                    <a className="insta" href="https://www.instagram.com/molinosala/?hl=es-la"><img src="/assets/sm-insta.svg" alt="Instagram"/></a>
                                    {/* <a className="tiktok" href="https://www.tiktok.com/@molinosala"><img src="/assets/sm-tiktok.svg" alt="Tik Tok"/></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="div80"></div>
            <Switch>
                <Route path="/productos">
                    <Productos />
                </Route>
                <Route path="/arroz">
                    <Arroz />
                </Route>
                <Route path="/snacks">
                    <Snacks />
                </Route>
                <Route path="/recetas/:id">
                    <PaginaReceta />
                </Route>
                <Route path="/recetas">
                    <Recetas />
                </Route>
                <Route path="/libre-de-gluten">
                    <LibreDeGluten />
                </Route>
                <Route path="/donde-encontrarnos">
                    <DondeEncontrarnos />
                </Route>
                <Route path="/sustentabilidad">
                    <Sustentabilidad />
                </Route>
                <Route path="/vende-molinos-ala">
                    <VendeMolinos />
                </Route>
                <Route path="/politicasdeinocuidad">
                    <Politicasdeinocuidad />
                </Route>

                <Route path="#contacto">
                </Route>
                <Route path="/">
                    <Home />
                </Route>
                <Route component={NoMatch} />
            </Switch>
            <Contacto/>
            <Footer/>
        </Router>
        );
    }

export default Routes;