import React from 'react';
import Underline from './Underline'
import '../scss/HeroHome.scss'

const HeroHome = (props) => {
    return (
        <div className="HeroHome">
            <div className="img-container">
                <div className={`resize_fit_center ${props.imgBanner}`}></div>
            </div>
        </div>
        )
    }
export default HeroHome;