import React from 'react';
// import JuicerFeed from 'react-juicer-feed';
import Underline from './Underline';
import '../scss/Redes.scss'

// function isPreRendering() {
//     return navigator.userAgent === 'ReactSnap';
// };

const Productos = (props) => {
    return (
        <div className="redes py-5">
            <div className="container">
                <h2 className="titleRedes">
                    <Underline className="color-green" underlinedtext="redes" />
                </h2>
                <div className="col-12" async defer>
                {/* <JuicerFeed feedId='molinosala' /> */}
                    <ul class="juicer-feed" data-feed-id="molinosala" async defer>
                        <h1 class="referral">
                            {/* <a href="https://www.juicer.io">Powered by Juicer.io</a> */}
                        </h1>
                    </ul>
                </div>
            </div>
        </div>
        )
    }
export default Productos;