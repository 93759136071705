import React from 'react';
import Underline from './Underline';
import Highlighted from './Highlighted';
import '../scss/Titulo.scss'
const Productos = (props) => {
    return (
        <>
            <h1 className="clasetitulo">{props.sinEfecto}<Underline className="color-green" underlinedtext={props.underlined} /><Highlighted highlightedText={props.highlited}/></h1>
        </>
        )
    }
export default Productos;