import React, {useState} from 'react';
import recipes from './Recetas.json';
import ModuloReceta from './ModuloReceta';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import '../scss/BuscadorRecetas.scss'

const BuscadorRecetas = () => {
    const [comidas, setComidas] = useState()
  
    const handleChange = (event) => setComidas(event.target.value)
    const filteredRecipes = comidas ? recipes.filter(recipe => recipe.comidas === comidas) : recipes
  
    return <div className="buscadorRecetas container pt-5">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <div className="col-md-4 mb-5 ">
                  <select value={comidas} onChange={handleChange}>
                    <option value="">Todas</option>
                    <option value="Desayuno y Merienda">Desayuno y Merienda</option>
                    <option value="Postre">Postre</option>
                    <option value="Brunch y salsas para dipear">Brunch y salsas para dipear</option>
                    <option value="Almuerzo y Cena">Almuerzo y Cena</option>
                  </select>
                </div>
              </div>
                  <ResponsiveMasonry 
                  columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                  >
                    <Masonry>
                      {filteredRecipes.map(recipe => <ModuloReceta name={recipe.name} photo={recipe.photo} arrow={recipe.arrow} id={recipe.id}/>)}
                    </Masonry>
                  </ResponsiveMasonry>
            </div>
  }
export default BuscadorRecetas;