import React, {useEffect, useState} from 'react';
import Recaptcha from 'react-google-invisible-recaptcha';
import {BrowserRouter as Router,Switch,Route,Link} from "react-router-dom";
import '../scss/Contacto.scss';
  
    
const BASE_API_URL = 'https://molinosala.com.ar/api/contacto'
const Contacto = () => {
        const [formSended, setFormSended] = useState(false)
        let recaptcha

        const blockButton = () => setFormSended(true)
        const handleSubmitClick = () => {
            blockButton()
            const modal = document.getElementById("modal-contacto")
            if(nombreContacto && mailContacto && mensajeContacto){
                modal.style.display = "block";
            }
            handleSubmit()
        }
        const [nombreContacto, setNombreContacto] = useState()
        const [mailContacto, setMailContacto] = useState()
        const [mensajeContacto , setMensajeContacto] = useState()
        const [errorsc, setErrorsc] = useState([])
        const [okmsjc, setokmsjc] = useState([])
    
        // console.log('errorsc', errorsc)

        const handleSubmit = () => {
            // console.log('click')
            const data = {
                nombreContacto,
                mailContacto,
                mensajeContacto
            }
            // console.log('data', data)
            fetch(BASE_API_URL, {
                method: 'POST',
                headers:{
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(response => {
                // console.log('response', response)
                if(response.status === 201){
                    setNombreContacto('')
                    setMailContacto('')
                    setMensajeContacto('')
                    setokmsjc(['Gracias por su consulta'])
                } else {
                    response.json().then(body => {
                        // console.log('body', body)
                        // console.log('Object.entries(body)[0]', Object.entries(body)[0])
                        const errorMessage = Object.entries(body)[0][1] || ['Falló el Envio']
                        setErrorsc(errorMessage)
                    })
                }
            }).catch(errorsc => {
                setErrorsc(['Falló el Envio'])
            })
        }
        const onResolved = () => {
            // console.log( 'Recaptcha resolved with response: ' + recaptcha.getResponse() );
        }
        useEffect(() => {
            //---------------------MODAL--------------------------
            const modal = document.getElementById("modal-contacto")
            const btn = document.getElementById("btn-contacto")
            const span = document.getElementById("close")
    
            span.onclick = function() {
                // console.log('x clicked')
                modal.style.display = "none";
            }
            
            window.onclick = function(event) {
                if (event.target == modal) {
                    modal.style.display = "none";
                }
            }
        })
    
  return (
      <div className="position-relative">
        <img className="borde" src="/assets/borde-dark.svg" alt="borde"/>
        <div className="contacto py-5 p-md-5">
            <div className="container">
                <div className="row">
                    <div className="position-relative">
                        <img className="decoration" src="/assets/arroz-footer.png" alt="Arroz"/>
                    </div>
                    <div className="col-12 col-sm-6">
                        <h5>CONTACTO</h5>
                        <form className="row pb-5 divisor divisorNone">
                            <div id="modal-contacto" class="modal">
                                <div class="modal-content">
                                    <span id="close" className="d-flex justify-content-center ">&times;</span>
                                    <p className="error">{errorsc[0]}</p>
                                    <p className="okmsj">{okmsjc[0]}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="d-flex flex-column pb-4">
                                    <label>Nombre y Apellido</label>
                                    <input className="textInput" name="nombreContacto" value={nombreContacto} onChange={({target: {value}}) => setNombreContacto(value)} required></input>
                                </div>
                                <div className="d-flex flex-column pb-4">
                                    <label>Email</label>
                                    <input className="textInput" name="mailContacto" value={mailContacto} onChange={({target: {value}}) => setMailContacto(value)} required></input>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex flex-column">
                                <label >Mensaje</label>
                                <textarea name="mensajeContacto" rows="7" cols="40" value={mensajeContacto} onChange={({target: {value}}) => setMensajeContacto(value)} required></textarea>
                                <div className="d-flex justify-content-end" id="btn-contacto">
                                    <input className="submitBtn mt-4" type="submit" value="Enviar" disabled={formSended} onClick={handleSubmitClick}></input>
                                </div>
                                <Recaptcha
                                    ref={ref => recaptcha = ref}
                                    sitekey="6LfEf-sZAAAAAHyex1NWAsjQoD1odWWFAIeL7H6X"
                                    onResolved={ onResolved }
                                    onLoaded = {() => console.log('loaded')}
                                    onError= {() => console.log('error')}
                                />
                            </div>
                        </form>
                    </div>
                    <div className="col-12 col-sm-6 pt-5 divisorLateral">
                        <div className="divisor pb-5 pb-md-3">
                            <a className="d-flex align-content-start mb-3" href="https://www.adecoagro.com/sumate" target="_blank"><p className="links">TRABAJÁ CON NOSOTROS</p> <img className="mx-2" src="/assets/arrow-right.svg" alt="Arrow"/></a>
                            <Link className="d-flex align-content-start mb-3" to="/vende-molinos-ala"><p className="links">NUESTROS DISTRIBUIDORES</p><img className="mx-2" src="/assets/arrow-right.svg" alt="Arrow"/></Link>
                            <Link className="d-flex align-content-start mb-3" to="/vende-molinos-ala#formVendeMolinos"><p className="links">DISTRIBUÍ CON NOSOTROS</p><img className="mx-2" src="/assets/arrow-right.svg" alt="Arrow"/></Link>
                        </div>
                        <div className="divisor py-5 py-md-3">
                            <p className="bold">PARA RECLAMOS O CONSULTAS</p>
                            <p className="d-md-none m-0 description">0 800 888 3646</p>
                            <p className="d-md-none m-0 description">atencionalconsumidor@adecoagro.com</p>
                            <p className="d-none d-md-flex mb-md-0">0 800 888 3646 / atencionalconsumidor@adecoagro.com</p>
                        </div>
                        <div className="mt-5 mt-md-3 d-md-flex">
                            <p className="bold pr-md-3 mb-md-0">SEGUINOS</p>
                            <div className="row col-6 col-md-4 justify-content-start">
                                <a href="https://es-la.facebook.com/MOLINOSALA/" target="_blank"><img className="face mx-2" src="/assets/sm-face.svg" alt="Facebook"/></a>
                                <a href="https://www.instagram.com/molinosala/?hl=es-la" target="_blank"><img className="mx-3" src="/assets/sm-insta.svg" alt="Instagram"/></a>
                                {/* <a href="https://www.tiktok.com/@molinosala" target="_blank"><img src="/assets/sm-tiktok.svg" alt="TikTok"/></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
export default Contacto;
