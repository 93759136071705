import React, {useEffect, useState} from 'react';
// import Recaptcha  from 'react-recaptcha'
import Underline from './Underline';
import '../scss/FormVendeMolinos.scss'
import Recaptcha from 'react-google-invisible-recaptcha';
const BASE_API_URL = 'https://molinosala.com.ar/api/distribuidor'

const FormVendeMolinos = () => {
    const [formSended, setFormSended] = useState(false)
    let recaptcha

    const blockButton = () => setFormSended(false)
    const enableButton = () => setFormSended(false)

    const handleSubmitClick = (e) => {
        e.preventDefault()
        const modal = document.getElementById("modal-contacto-vm")
        if(nombreDistribuidor && mailDistribuidor && direccionDistribuidor && provinciaDistribuidor && cuitDistribuidor && telefonoDistribuidor && ciudadDistribuidor && mensajeDistribuidor && postalDistribuidor){
            modal.style.display = "block";
        }
        handleSubmit()
    
    }
    const [nombreDistribuidor, setNombreDistribuidor] = useState('')
    const [mailDistribuidor, setMailDistribuidor] = useState('')
    const [direccionDistribuidor, setDireccionDistribuidor] = useState('')
    const [provinciaDistribuidor, setProvinciaDistribuidor] = useState('Buenos Aires')
    const [cuitDistribuidor, setCuitDistribuidor] = useState('')
    const [telefonoDistribuidor, setTelefonoDistribuidor] = useState('')
    const [ciudadDistribuidor, setCiudadDistribuidor] = useState('')
    const [mensajeDistribuidor, setMensajeDistribuidor] = useState('')
    const [postalDistribuidor , setPostalDistribuidor] = useState('')
    const [errors, setErrors] = useState([])
    const [okmsj, setokmsj] = useState([])



    const handleSubmit = () => {
        blockButton()

        const data = {
            nombreDistribuidor,
            mailDistribuidor,
            direccionDistribuidor,
            provinciaDistribuidor,
            cuitDistribuidor,
            telefonoDistribuidor,
            ciudadDistribuidor,
            mensajeDistribuidor,
            postalDistribuidor
        }
        fetch(BASE_API_URL, {
            method: 'POST',
            headers:{
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if(response.status === 201){
                setNombreDistribuidor('')
                setMailDistribuidor('')
                setDireccionDistribuidor('')
                setProvinciaDistribuidor('')
                setCuitDistribuidor('')
                setTelefonoDistribuidor('')
                setCiudadDistribuidor('')
                setMensajeDistribuidor('')
                setPostalDistribuidor('')
                setokmsj(['Gracias por su consulta'])
                setFormSended(true)
            } else {
                response.json().then(body => {
                const errorMessage = Object.entries(body)[0][1] || ['Falló el Envioo']
                setErrors(errorMessage)
            })
        }
        }).catch(errors => {
            setErrors(['Falló el Envio!'])
            enableButton()
        })
    }
    // .then(document.getElementById('formDistribuidor').reset())
    //     .catch(document.getElementById('formDistribuidor').reset())
    const resetForm = () =>{
        Document.getElementById('formDistribuidor').reset();
    }
    const onResolved = () => {
        // console.log( 'Recaptcha resolved with response: ' + recaptcha.getResponse() );
    }

    useEffect(() => {
        //---------------------MODAL--------------------------
        const modalD = document.getElementById("modal-contacto-vm")
        const btnD = document.getElementById("btn-contacto-vm")
        const spanD = document.getElementById("close-vm")

        // btnD.onclick = function() {
        //     if(nombreDistribuidor && mailDistribuidor && direccionDistribuidor && provinciaDistribuidor && cuitDistribuidor && telefonoDistribuidor && ciudadDistribuidor && mensajeDistribuidor && postalDistribuidor){
        //         modalD.style.display = "block";
        //     }
        // }

        spanD.onclick = function() {
            // console.log('x clicked')
            modalD.style.display = "none";
            setErrors([]);
        }

        window.onclick = function(event) {
            if (event.target == modalD) {
                modalD.style.display = "none";
                setErrors([]);
            }
        }
    })

    return (
        <section className="formVende py-5" >
            <a id="formVendeMolinos" className="div80"></a>
            <div className="container">
                <h3 className="pb-3">¿querés distribuir<br/><Underline className="before80v" underlinedtext="nuestros productos?"/></h3>
                <p className="pb-3">Si estás interesado en distribuir nuestros productos te invitamos a contactarte con un ejecutivo de ventas, quien atenderá personalmente tu solicitud y te brindará la información necesaria.</p>
                <form id="formDistribuidor" onSubmit={handleSubmitClick}>
                    <div id="modal-contacto-vm" class="modal">
                        <div class="modal-content">
                            <span id="close-vm" className="d-flex justify-content-center ">&times;</span>
                            {okmsj[0] ? <p className="okmsj">{okmsj[0]}</p> : <p className="error">{errors[0]}</p> }
                             {/* <p className="error">{errors[0]}</p> */}
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-5 d-flex flex-column">
                            <label>Razón Social o Nombre</label>
                            <input type="text" name="nombreDistribuidor" className="textInput" value={nombreDistribuidor} onChange={({target: {value}}) => setNombreDistribuidor(value)}required></input>
                            <label>Mail</label>
                            <input type="email" name="mailDistribuidor" className="textInput" value={mailDistribuidor} onChange={({target: {value}}) => setMailDistribuidor(value)} required></input>
                            <label>Dirección</label>
                            <input type="text" name="direccionDistribuidor" className="textInput" value={direccionDistribuidor} onChange={({target: {value}}) => setDireccionDistribuidor(value)} required></input>
                            <label>Código Postal</label>
                            <input type="text" name="postalDistribuidor " className="textInput" value={postalDistribuidor } onChange={({target: {value}}) => setPostalDistribuidor (value)}></input>
                            <label name="provinciaDistribuidor">Provincia</label>
                            <select name="provincia" id="provincia" form="provincia" value={provinciaDistribuidor } onChange={({target: {value}}) => setProvinciaDistribuidor (value)} required>
                                <option value="Provincia">Provincia</option>
                                <option value="Buenos Aires">Buenos Aires</option>
                                <option value="Catamarca">Catamarca</option>
                                <option value="Chaco">Chaco</option>
                                <option value="Chubut">Chubut</option>
                                <option value="Córdoba">Córdoba</option>
                                <option value="Corrientes">Corrientes</option>
                                <option value="Entre Ríos">Entre Ríos</option>
                                <option value="Formosa">Formosa</option>
                                <option value="Jujuy">Jujuy</option>
                                <option value="La Pampa">La Pampa</option>
                                <option value="La Rioja">La Rioja</option>
                                <option value="Mendoza">Mendoza</option>
                                <option value="Misiones">Misiones</option>
                                <option value="Neuquén">Neuquén</option>
                                <option value="Río Negro">Río Negro</option>
                                <option value="Salta">Salta</option>
                                <option value="San Juan">San Juan</option>
                                <option value="San Luis">San Luis</option>
                                <option value="Santa Cruz">Santa Cruz</option>
                                <option value="Santa Fe">Santa Fe</option>
                                <option value="Santiago del Estero">Santiago del Estero</option>
                                <option value="Tierra del Fuego">Tierra del Fuego</option>
                                <option value="Tucumán">Tucumán</option>
                            </select>
                        </div>
                        <div className="col-md-5 d-flex flex-column">
                            <label>Cuit</label>
                            <input type="number" minlength="4" name="cuitDistribuidor" className="number" value={cuitDistribuidor } onChange={({target: {value}}) => setCuitDistribuidor (value)} required></input>
                            <label>Teléfono</label>
                            <input type="number" name="telefonoDistribuidor" className="number" value={telefonoDistribuidor } onChange={({target: {value}}) => setTelefonoDistribuidor (value)} required></input>
                            <label>Ciudad</label>
                            <input type="text" className="textInput" name="ciudadDistribuidor" value={ciudadDistribuidor } onChange={({target: {value}}) => setCiudadDistribuidor (value)} required></input>
                            <label>Mensaje o Consulta</label>
                            <input type="text" className="textInput msj" name="mensajeDistribuidor" value={mensajeDistribuidor } onChange={({target: {value}}) => setMensajeDistribuidor (value)} required></input>
                        </div>
                        <div className="col-10 d-flex justify-content-end" id="btn-contacto-vm">
                            <input className="submitBtn mt-4" type="submit" value="Enviar" disabled={formSended}></input>
                        </div>
                        <Recaptcha
                                ref={e => recaptcha = e}
                                sitekey="6LfEf-sZAAAAAHyex1NWAsjQoD1odWWFAIeL7H6X"
                                onResolved={ onResolved }
                            />
                    </div>
                </form>
            </div>
        </section>
        )
    }
export default FormVendeMolinos;