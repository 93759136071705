import React from 'react';
import '../scss/Faqs.scss';


const Faqs = (props) => {
    return (
        <div className="container faqs">
            <div className="row justify-content-center align-items-center">
                <div className="col-sm-8 p-0">
                    <div id="accordion">
                        <div className="card">
                            <div className="card-header faqsvende" id="headingOne">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target={`#${props.dataTarget}`} aria-expanded="false" aria-controls="collapseOne">
                                        <div className="row justify-content-between">
                                            <div className="text-left">
                                                <div className="w-100"><span className="boldFaq">DISTRIBUIDOR: </span>{props.distribuidor}</div>
                                                <div><span className="boldFaq">ZONA: </span>{props.zona}</div>

                                            </div>
                                            <div><img src="/assets/arrow-bottom.svg" alt=""/></div>

                                        </div>
                                    </button>
                                </h5>
                            </div>
                            <div id={props.dataTarget} className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                <div className="card-body cardVente">
                                    <div><span className="boldFaq">CONTACTO COMERCIAL: </span>{props.contacto}</div>
                                    <div><span className="boldFaq">TELEFONO: </span>{props.telefono}</div>
                                    <div><span className="boldFaq">DETALLE: </span>{props.detalle}</div>
                                    {props.respuesta}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        )
    }
    export default Faqs;