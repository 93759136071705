import React, {useState} from 'react';
import '../scss/BuscadorSucursales.scss'
import directions from './Directions.json';

const BuscadorSucursales = () => {
    const [provincia, setProvincia] = useState("Cadenas Nacionales")
  
    const handleChange = (event) => setProvincia(event.target.value)
    const filteredDirections = provincia ? directions.filter(direction => direction.provincia === provincia) : directions
    // console.log(provincia)
    return <div className="provinciaSucursales container d-flex justify-content-center mb-5">
                <div className="row justify-content-center">
                    <div className="col-md-3 bg-provincia d-flex flex-column justify-content-center align-items-center">
                        <img className="miscelanea" src="/assets/wave-white.svg" alt=""/>
                        <p>{provincia}</p>
                        <img className="miscelanea" src="/assets/wave-white.svg" alt=""/>
                    </div>
                    <div className="col-12 col-md-6">
                        <select className="mb-5" value={provincia} onChange={handleChange}>
                            <option value="Cadenas Nacionales">Cadenas Nacionales</option>
                            <option value="Buenos Aires">Buenos Aires</option>
                            <option value="Buenos Aires - CABA">Buenos Aires - CABA</option>
                            <option value="Buenos Aires - Zona Norte">Buenos Aires - Zona Norte</option>
                            <option value="Buenos Aires - Zona Oeste">Buenos Aires - Zona Oeste</option>
                            <option value="Buenos Aires - Zona Sur">Buenos Aires - Zona Sur</option>
                            <option value="Chaco">Chaco</option>
                            <option value="Chubut">Chubut</option>
                            <option value="Córdoba">Córdoba</option>
                            <option value="Corrientes">Corrientes</option>
                            <option value="Entre Rios">Entre Rios</option>
                            <option value="La Pampa">La Pampa</option>
                            <option value="La Rioja">La Rioja</option>
                            <option value="Mendoza">Mendoza</option>
                            <option value="Misiones">Misiones</option>
                            <option value="Neuquen">Neuquen</option>
                            <option value="Provincia de Buenos Aires">Provincia de Buenos Aires</option>
                            <option value="Rio Grande">Rio Grande</option>
                            <option value="Rio Negro">Rio Negro</option>
                            <option value="Rosario">Rosario</option>
                            <option value="Salta">Salta</option>
                            <option value="San Juan">San Juan</option>
                            <option value="San Luis">San Luis</option>
                            <option value="Santa Cruz">Santa Cruz</option>
                            <option value="Santa Fe">Santa Fe</option>
                            <option value="Santiago del Estero">Santiago del Estero</option>
                            <option value="Tierra del Fuego">Tierra del Fuego</option>
                            <option value="Tucuman">Tucuman</option>
                        </select>
                        {filteredDirections.map(direction => <div key={direction.cadenas}>{direction.cadenas?.map(cadena => <div className="direcciones pb-2">{cadena}</div>)}</div>)}
                    </div>
                </div>
            </div>
        
  }

export default BuscadorSucursales;