import React from 'react';
import '../scss/Underline.scss';

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(<Home />, rootElement);
// } else {
//   render(<Home />, rootElement);
// }
const Underline = (props) => {
return (
        <span className={`underlinetext ${props.className}`}>{props.underlinedtext}</span>
    )
}

// function Underline() {
//   return (
//     <div className="underlinetext">
//     </div>
//   );
// }

export default Underline;
