import React from 'react';
import { Link } from 'react-router-dom';
import '../scss/ModuloReceta.scss'

const ModuloReceta = ({name, photo, arrow, id}) => {
    return (
        <div className="grid-item">
            <div className="recetas mb-3">
                <Link to={`recetas/${id}`} alt={name}> 
                    <img className="imgPreview" src={photo} alt="Receta"/>
                    <div className="row xd">
                        <div className="col-8 pr-0">
                            <h4 className="titleRecipe py-2">{name}</h4>
                        </div>
                        <div className="">
                            <img className="arrow" src={arrow} alt="Arrow"/>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
        )
    }
export default ModuloReceta;