import React from 'react';
import Hero from './Hero';
import Highlighted from './Highlighted';
import Underline from './Underline'
import Faqs from './Faqs'
import '../scss/LibreDeGluten.scss'

const LibreDeGluten = (props) => {
    return (
        <section className="libreDeGluten">
            <Hero imgBanner="libre-de-gluten" titlefirst="todo el sabor," titleSecond="cero gluten"/>
            <section className="libre-info">
                <img className="sintacc pb-3" src="/assets/sintacc.svg" alt=""/>
                <div className="container conteinerLibre">
                    <div className="row">
                        <div className="col-md-6 px-0 position-relative bg-sandwitch">
                            {/* <img className="h-100" src='/assets/sandwitch.png' alt='sandwitch'/> */}
                            <img className="bordeLateral d-none d-md-flex" src="/assets/borde-lateral.svg"/>
                            <img className="bordeLateralbot" src="/assets/borde-inferior-medio.svg"/>
                        </div>
                        <div className="col-md-6 py-5 px-md-0">
                            <div className="container pl-md-5">
                                <img className="miscelanea pb-3" src="/assets/wave.svg" alt=""/>
                                <h4 className="TituloLibre pb-3">el sabor de siempre</h4>
                                <p className="pb-3 col-md-10 px-0"> ¡No hay nada como unas ricas tostadas para arrancar el día, especialmente si son libres de gluten! <br/>Al hacer nuestros productos con arroz garantizamos que ninguno de ellos contenga gluten, así que todo lo que tenés que hacer es disfrutarlo. <br/>Es tan fácil como combinar tus tostadas con lo que más te guste ¡lo único que le va a faltar a tu desayuno es el gluten!</p>
                                <img className="sello" src="/assets/sello-naturaleza.svg" alt=""/>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container conteinerLibre">
                    <div className="row">
                        <div className="col-md-6 py-5 px-md-0 ">
                            <div className="container pl-md-5">
                                <img className="miscelanea pb-3" src="/assets/wave.svg" alt=""/>
                                <h4 className="TituloLibre pb-3 ">SMOOTHIE DE BANANA Y FRUTILLA</h4>
                                <p className="pb-3">Ingredientes:</p>
                                <ul className="listIngredients pb-3">
                                    <li>- Nuestras tostadas integrales</li>
                                    <li>- ½ banana o una entera -dependiendo del tamaño-.</li>
                                    <li>- 3 frutillas grandes. </li>
                                    <li>- Una cucharada de crema o de leche.</li>
                                    <li>- Cacao</li>
                                </ul>
                                <p className="pb-3 col-md-10 px-0">Colocá las frutillas y la banana en el freezer al menos por 1 hora. Retiralas en en el momento de preparar el smoothie y mixealas hasta obtener una mezcla cremosa. Luego sumá una cucharada de crema o de leche para darle más cremosidad.  Por ultimo, serví en un bowl con frutas a gusto y completá con tostadas integrales en trozos y espolvoreá con cacao amargo en polvo. Tu smoothie está listo, ¡a disfrutarlo! </p>
                                <img className="sello" src="/assets/sello.svg" alt=""/>
                            </div>
                        </div>
                        <div className="col-md-6 px-0 bg-smoothie">
                            {/* <img className="h-100" src='/assets/smoothie.jpg' alt='sandwitch'/> */}
                            <img className="bordeLateralRight" src="/assets/borde-lateral.svg"/>
                            <img className="bordeLateraltop" src="/assets/borde-inferior-medio.svg"/>
                        </div>
                    </div>

                </div>
            </section>
            <section>
                
            </section>
            <div className="nuestraLinea">
                <div className="container py-5">
                    <h4 className="title mb-5">conocé nuestra<br/> <Underline className="before80" underlinedtext="línea completa"/></h4>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row position-relative">
                                <div className="position-absolute bg-green">
                                </div>
                                <div className="col-md-6 d-flex justify-content-center justify-content-md-end">
                                    <a href="/arroz">
                                        <div className="circle d-flex flex-column justify-content-center align-items-center">
                                            <img className="icon" src="/assets/icon-rice.svg" alt="rice"/>
                                            <p>arroz</p>
                                            <Highlighted highlightedText="ver más"/>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6">
                                    <img src="/assets/group-rice.png"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row position-relative">
                            <div className="position-absolute bg-green left-bg">
                            </div>
                                <div className="col-md-6 d-flex justify-content-center justify-content-md-end">
                                    <a href="/snacks" className="">
                                        <div className="circle d-flex flex-column justify-content-center align-items-center">
                                            <img className="icon" src="/assets/icon-snacks.svg" alt="rice"/>
                                            <p>snacks</p>
                                            <Highlighted highlightedText="ver más"/>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6">
                                    <img src="/assets/group-snacks.png"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="borde" src="/assets/borde.svg" alt="borde"/>
            </div>
            <div className="faqsSection py-5">
                <h4 className="title pb-5"><Underline className="before80v" underlinedtext="preguntas frecuentes"/></h4>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <Faqs dataTarget="collapseOne" pregunta="¿Qué es el gluten?" respuesta="El gluten es una mezcla de proteínas que se encuentran naturalmente en el trigo, la avena, el centeno, la cebada y los cruces de estos granos."/>
                    <Faqs dataTarget="collapseTwo" pregunta="¿Por qué es importante saber si un alimento no contiene gluten?" respuesta="Los alimentos sin gluten benefician a los consumidores que padecen la enfermedad celíaca, un trastorno autoinmune inflamatorio crónico hereditario que se estima que afecta a muchas personas. Para las personas que tienen la enfermedad celíaca, el consumo de gluten da como resultado la destrucción del revestimiento del intestino delgado y el riesgo de otras afecciones graves de salud."/>
                    <Faqs dataTarget="collapseThree" pregunta="¿Qué alimentos pueden etiquetarse como 'sin gluten'?" respuesta="Se permite etiquetar un alimento  como 'sin gluten' si  no contiene ninguno de los siguientes:
                    *Cualquier tipo de trigo, avena, cebada, centeno o cruces de estos granos.
                    *Un ingrediente derivado de estos granos que no ha sido procesado para eliminar el gluten.
                    *Un ingrediente derivado de estos granos y que ha sido procesado para eliminar el gluten, si da como resultado que el alimento contenga 10 partes por millón (ppm) o más de gluten."/>
                    <Faqs dataTarget="collapseFour" pregunta="¿Qué productos Molinos Ala están etiquetados como sin gluten?" respuesta="De la misma forma que el grano entero no posee esta proteína, su versión en harina tampoco. Por este motivo, la harina de arroz es un tipo de harina muy utilizado por las personas intolerantes al gluten para elaborar rebozados o productos de repostería."/>
                </div>
            </div>
        </section>
        )
    }
export default LibreDeGluten;