import React from 'react';
import Highlighted from './Highlighted';
import '../scss/SustentabilidadSections.scss'

const SustentabilidadSections = () => {
    return (
        <section className="sectionSustentabilidad my-5">
            <div className="container position-relative">
                <div className="row mb-4">
                    <div className="col-md-6 mb-4">
                        <div className="row px-3 mb-4">
                            <img className="icon-sustentabilidad pb-2 mr-3" src="/assets/icon-001.svg"/>
                            <h3 className="titles-sections d-none d-md-block">desarrollamos un <br/><Highlighted highlightedText="modelo"/> sustentable</h3>
                            <h3 className="titles-sections d-md-none">desarrollamos un <br/><Highlighted highlightedText="modelo"/> <br/>sustentable</h3>
                        </div>
                        <p className=""><span className="boltext">Protegemos el suelo</span> de los riesgos de erosión, mejoramos su fertilidad y productividad a través de la siembra directa y labranza mínima.</p>
                        <p className=""><span className="boltext">Reducimos el consumo de agua</span> en un 30% mediante el uso de la tecnología de riego por Nivel Cero y además ahorramos energía!</p>
                        <p className=""><span className="boltext">Producimos cuidando la biodiversidad</span> que habita en nuestros campos, identificando la fauna y la flora de cada lugar y respetando las áreas naturales, para que la biodiversidad pueda circular con naturalidad.</p>
                        <img className="miscelanea" src="/assets/wave.svg" alt=""/>
                    </div>
                    <div className="col-md-6">
                        <img className="img-bisel" src="/assets/imagen-01.png"/>
                    </div>
                </div>

                <div className="rowmovida">
                    <div className="rowajuste">
                        <div className="row flex-md-row-reverse">
                            <div className="col-md-6 mt-3 mb-4 infobloque">
                                <div className="row px-3 mb-4">
                                    <img className="icon-sustentabilidad pb-2 mr-3" src="/assets/icon-002.svg"/>
                                    <h3 className="titles-sections d-none d-md-block">conocemos <Highlighted highlightedText=" nuestras "/><br/> semillas</h3>
                                    <h3 className="titles-sections d-md-none">conocemos <br/><Highlighted highlightedText=" nuestras "/><br/> semillas</h3>
                                </div>
                                <p className="">Las cosechamos y cuidamos durante todo el proceso productivo hasta que llegan a tu mesa.</p>
                                <img className="miscelanea" src="/assets/wave.svg" alt=""/>
                            </div>
                            <div className="col-md-6">
                                <img className="img-bisel" src="/assets/imagen-02.png"/>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="rowmovida">
                    <div className="rowajuste">
                        <div className="row">
                            <div className="col-md-6 mb-4 infobloque">
                                <div className="row px-3 mb-4">
                                    <img className="icon-sustentabilidad pb-2 mr-3" src="/assets/icon-003.svg"/>
                                    <h3 className="titles-sections d-none d-md-block">cuidamos <Highlighted highlightedText="nuestros"/><br/> productos</h3>
                                    <h3 className="titles-sections d-md-none pt-4">cuidamos <br/><Highlighted highlightedText="nuestros"/><br/> productos</h3>
                                </div>
                                <p className="">Estamos presentes a lo largo de todo el proceso productivo, para poder ofrecer <span className="boltext">productos cuidados al alcance de todos.</span></p>
                                <img className="miscelanea" src="/assets/wave.svg" alt=""/>
                            </div>

                            <div className="col-md-6">
                                <img className="img-bisel" src="/assets/imagen-03.png"/>
                            </div>

                        </div>
                    </div>


                </div>

            </div>
        </section>
        )
    }
export default SustentabilidadSections;