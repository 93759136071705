import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import { Link, NavLink } from 'react-router-dom';
import recipes from './Recetas.json'
import '../scss/PaginaRecetas.scss'

const PaginaReceta = () => {
    const {id} = useParams()
    const currentRecipe = recipes.find(recipes => recipes.id === id)
    // console.log('currentRecipe.tituloinstruccion1', currentRecipe.tituloinstruccion1)
    return <div className="recipes">
            <div className="row">
                <div className="col-md-6" style={{
                    backgroundImage: `url(${currentRecipe.photo})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>
                </div>
                <div className="col-md-6 py-5">
                    <div className="container">
                        <img className="miscelanea pb-5" src="/assets/wave.svg" alt=""/>
                        <h4 className="TituloReceta pb-5">{currentRecipe.name}</h4>
                        <div className="row mx-0">
                        <div className="col-md-6 px-0">
                                <p className="pb-3 subtitle"> {currentRecipe.tituloinstruccion2} </p>
                                <p className="pb-3"> {currentRecipe.instrucciones2} </p>
                                <p className="pb-3 subtitle"> {currentRecipe.tituloinstruccion3} </p>
                                <p className="pb-3"> {currentRecipe.instrucciones3} </p>
                                
                            </div>
                            <div className="col-md-6">
                                <p className="pb-3 subtitle"> {currentRecipe.tituloinstruccion1} </p>
                                {currentRecipe.instrucciones1.map(instruccion => <p className="pb-3">{instruccion}</p> )}
                            </div>
                        </div>
                        <Link to="/recetas">
                            <p className="btnVerde">Ver más recetas</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
}
export default PaginaReceta